import React, { useEffect, useState } from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import Slide from "../components/slide";
import { Heading, Button, Box } from "@chakra-ui/react";
import { parseQueryString } from "../utils/url";
//import { rcAdsRefresh } from "../utils/rcAdsRefresh";
//import { yeet } from "../utils/yeet";
import Helmet from "react-helmet"
import GoogleAd from "../components/ads/google";
import Flipp from "../components/ads/flipp";

function Slideshow(props) {
  // This is moved into a useEffect to make sure SSR doesn't barf
  const [queryParams, setQueryParams] = useState({
    utm_source: "organic",
    utm_campaign: 0,
    utm_keyword: 0,
  });

  const { slides, title, category } = props.data.slideshowsJson;

  const [slideShowActivated, setSlideShowActivated] = useState(false);

  const [slideNumber, setSlide] = useState(0);

  const { location } = props;

  const slide = slides[slideNumber];

  const siteName = props.data.site.siteMetadata.title.replace(".", "-");
  const siteUrl = props.data.site.siteMetadata.siteUrl;

  const pageView = (e) => {

    window.scroll(0, 0);


    // Pageview track to event service
    setSlide(parseInt(e.target.value), 10);

    //if (queryParams && queryParams.utm_source === "revcontent") {

      try {
        window.gtag("event", "slide_click", {});
        window.gtag("event", "page_view", {});
      }  catch (err) {
        console.warn(err.message);
      }

    //}

    if (parseInt(e.target.value) === Object.keys(slides).length) {
      window.location.href = '/end-of-gallery'
    }
  };

  useEffect(() => {
    setQueryParams(parseQueryString());
  }, []);

  useEffect(() => {
    setSlideShowActivated(
      //window.location.search.match(/utm_source=(revcontent|jeeng|fb|orange)/i) != null
      true
    );
  }, [queryParams]);


  return (
      <Layout location={location}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Box className="blog-content">
          <Heading mt={2} color="blue.800">
            {title}
          </Heading>

          {slideShowActivated ? (
              <div>
                <Slide slide={slide} siteName={siteName}/>
                {(siteUrl && siteUrl.includes('sociallypolite')) ? (
                    <div style="width:100%;min-height:90px;float: left;">
                      <GoogleAd slot={9181423624} layout={"-6z+ex+e-5b+7u"} key={Math.random()}/>
                    </div>
                ) : ""}
                <Button
                    value={slideNumber + 1}
                    width={"100%"}
                    size="lg"
                    colorScheme="blue"
                    mb={4}
                    id="bro_butt"
                    marginTop="10px"
                    height="90px"
                    fontSize="22px"
                    onClick={pageView}
                >
                  
                  {category === "Quiz" ? (slideNumber === 0 ? "Start Quiz" : "Next Question"): (slideNumber === 0 ? "Start Slideshow" : "Next Slide")}
                   
                </Button>

                {(siteUrl && siteUrl.includes('sociallypolite')) ? (
                  <div>
                    <div>
                      <Flipp site="1280249" zone="310827" domain="Socially Polite Standard"></Flipp>
                    </div>
                      <div style="width:100%;min-height:90px;">
                        <GoogleAd slot={9181423624} layout={"-6z+ex+e-5b+7u"} key={Math.random()}/>
                      </div>
                    </div>
                ) : ""}
              </div>
          ) : (
              slides.map((slide, i) => <Slide key={i} slide={slide} />)
          )}

        </Box>
      </Layout>
  );
}

export default Slideshow;

export const pageQuery = graphql`
  query ($slug: String!) {
    site {
  	  siteMetadata {
	    title
      siteUrl
      }
    } 
    slideshowsJson(slug: { eq: $slug }) {
      title
      slug
      category
      slides {
        body
        imageTitle
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
