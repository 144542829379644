import React, { useEffect } from "react";
const Google = (props) => {
    const { slot,layout,key } = props;

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5555030135342556";
        script.async = true;
        script.crossOrigin = "anonymous";

        document.body.appendChild(script);

        const script2 = document.createElement('script');

        script2.innerHTML = "(adsbygoogle = window.adsbygoogle || []).push({});";

        document.body.appendChild(script2);

        return () => {
            document.body.removeChild(script);
            document.body.removeChild(script2);
        }
    });

    return (
        <ins className="adsbygoogle"
             style="display:block"
             data-ad-format="fluid"
             data-ad-layout-key={layout}
             data-ad-client="ca-pub-5555030135342556"
             data-ad-slot={slot}
             key={key}></ins>
    );
};

export default Google;
