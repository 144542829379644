export const parseQueryString = () => {
    let search = window.location.search.substring(1);

    if (search !== "") {

        if (search.slice(-1) === "&") {
            search = search.substring(0, search.length - 1);
        }

        return JSON.parse(
            '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
            function (key, value) {
                return key === "" ? value : decodeURIComponent(value);
            }
        );

    } else {
        return;
    }


};
