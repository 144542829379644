import { useEffect } from "react";

const Quiz = (props) => {
    // You can remove the destructuring of props if it's not being used
    // const { } = props;

    useEffect(() => {
        // Check if the script is already loaded
        const existingScript = document.getElementById("quizjs");

        if (!existingScript) {
            // Create and append the script only if it doesn't exist
            const script = document.createElement("script");
            script.src = "https://webloadr.com/quiz.js";
            script.id = "quizjs";
            script.async = true;
            document.body.appendChild(script);
        }

        // Optional: Clean-up function if needed
        return () => {
            // This will remove the script when the component unmounts
            // Uncomment if you want to remove the script on unmount
            // if (existingScript) document.body.removeChild(existingScript);
        };
    }, []); // Empty dependency array ensures this runs only once after initial render

    return "";
};

export default Quiz;
