import React, { useEffect } from "react";
const Flipp = (props) => {
    const { site,zone,domain } = props;

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webloadr.com/wc_loader.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    });

    return (
        <div id="web_connections_deal_loader" data-site-id={site} data-zone-id={zone} data-domain={domain}></div>
    );
};

export default Flipp;

