import * as React from "react";
import { Heading, Box } from "@chakra-ui/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import GoogleAd from "../components/ads/google";

const Slide = ({ slide, siteName }) => {
  return (
    <div>
      {slide.imageTitle && slide.category === "Quiz" ? (
        <Heading mt={2} as="h4" size={"md"}>
          {slide.imageTitle}
        </Heading>
      ): ""}
      <Box mb={2} mt={2}>
        <GatsbyImage image={getImage(slide.image)} alt="" />
          {(siteName && siteName === 'sociallypolite') ? (
              <GoogleAd slot={1222813647} layout={"-fb+5w+4e-db+86"} key={Math.random()}/>
          ) : ""}
      </Box>
      {slide.imageTitle && slide.category === "Quiz" ? "": <Heading mt={2} as="h4" size={"md"}>
        {slide.imageTitle}
      </Heading>}
      <Box mt={2} mb={2}>
        <div dangerouslySetInnerHTML={{ __html: slide.body }}></div>
      </Box>
    </div>
  );
};

export default Slide;
