import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Box, Text } from "@chakra-ui/react";
import Footer from "../components/footer";
//import GoogleAd from "../components/ads/google";
import "./layout.css"
//import Navigation from "../components/navigation";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Helmet from "react-helmet"
import GoogleAd from "./ads/google";
import Quiz from "./ads/quiz";

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;

  const layoutData = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          description
          siteUrl
          title
          logo
        }
      }
      gitCommit(latest: { eq: true }) {
        hash
        date(formatString: "YYYY-MM-DD hh:mm:ss")
      }
    }
  `);

  const siteData = layoutData.site.siteMetadata;

  const logo = () => {
    switch (siteData.title) {
      case "zyptrend":
        return (
            <StaticImage
                height={64}
                src="../../static/logos/zyptrend.png"
                alt="all the trends"
            />
        );
      case "fancydoer":
        return (
            <StaticImage
                height={64}
                src="../../static/logos/fancydoer.png"
                alt="do it fancy"
            />
        );
      case "sociallypolite":
        return (
            <StaticImage
                height={64}
                src="../../static/logos/sociallypolite.png"
                alt="don't be rude"
            />
        );
      default:
        return (
          <StaticImage
            height={64}
            src="../../static/logos/zyptrend.png"
            alt="all the trends"
          />
        );
    }
  };

  return (
    <Container mt={2} maxW="container.lg" data-is-root-path={isRootPath}>
        <Helmet>
            <script async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5555030135342556"
                    crossOrigin="anonymous"></script>

            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-273618533-1"></script>
            <script>{`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', 'UA-273618533-1');
            `}</script>


            <script>
            {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '110511919643144');
            fbq('track', 'PageView');
            `}</script>

           
            <link rel="stylesheet" href="https://webloadr.com/quiz.css"></link>
           

        </Helmet>
      <div style={{ float: `left`, marginRight: `30px`, width: `100%` }}>
        <a href="/">{logo()}</a>
      </div>
        {/*<Navigation/>*/}
        <Container p={0} maxW="container.lg" display={{ md: "flex" }}>
            {children}
            <Box
                flexShrink={0}
                w={{ lg: "340px" }}
                display={{ base: "block", lg: "block" }}
                textAlign="center"
                paddingRight= '0px'
                minWidth={300}
            >
                <div style={{ width: `330px`, padding: `0 0 0 10px` }}>
                {(siteData.title && siteData.title === 'sociallypolite') ? (
                        <GoogleAd slot={9181423624} layout={"-6z+ex+e-5b+7u"} key={Math.random()}/>
                    ) : ""}
                </div>
               
                <div id="M931239ScriptRootC1565727" style={{ padding: `6px` }}> 
                </div> 
                <script src="https://jsc.adskeeper.com/s/o/sociallypolite.com.1565727.js" async> 
                </script> 

 
                <div style={{ width: `330px`, padding: `0 0 0 10px`, height: `600px` }}>
                {(siteData.title && siteData.title === 'sociallypolite') ? (
                        <GoogleAd slot={9181423624} layout={"-6z+ex+e-5b+7u"} key={Math.random()}/>
                    ) : ""}
                </div>
            </Box>
        </Container>
      <Footer site={siteData}/>
      <Quiz></Quiz>
    </Container>
  );
};

export default Layout;
